import { Machine } from 'xstate'
import { occsnClient } from '../libs/occasion-sdk'

const fetchUser = async () => {
  const response = await fetch('/mocks/user.json')
  // const response = await fetch('https://occ.sn/api/v1/products')
  return response.json()
}

const fetchApp = async () => {
  const products = await occsnClient().Product.includes('merchant').all()
  return { products: products.toArray() }
}

export const appMachine = Machine(
  {
    id: 'authentication',
    initial: 'unauthorized',
    states: {
      unauthorized: {
        on: {
          LOGIN: 'authorizing',
        },
      },
      authorizing: {
        invoke: {
          id: 'fetchUser',
          src: fetchUser,
          onDone: [
            {
              target: 'loading',
              actions: 'setApp',
              cond: (context, event) => event.data?.user,
            },
            { target: 'unauthorized', actions: 'redirectToLoginPage' },
          ],
          onError: {
            target: 'unauthorized',
            actions: 'redirectToLoginPage',
          },
        },
      },
      loading: {
        invoke: {
          id: 'fetchApp',
          src: fetchApp,
          onDone: {
            target: 'ready',
            actions: 'setApp',
          },
          onError: {
            target: 'failed',
            actions: 'debug',
          },
        },
        on: {
          LOGOUT: {
            target: 'unauthorized',
            actions: 'logOut',
          },
        },
      },
      ready: {
        // type: 'final',
        on: {
          LOGOUT: {
            target: 'unauthorized',
            actions: 'logOut',
          },
        },
      },
      failed: {
        on: {
          RETRY: 'loading',
          LOGOUT: {
            target: 'unauthorized',
            actions: 'logOut',
          },
        },
      },
    },
  },
  {
    actions: {
      debug: (context, event) => {
        console.log(event)
        // debugger
      },
    },
  },
)
