import { useEffect } from 'react'
import { useLocalStorage } from 'react-use'
import { useMachine } from '@xstate/react'

export function usePersistedMachine(key, machine, options) {
  const [storedState, setStoredState] = useLocalStorage(key, null)
  if (storedState) options.state = storedState
  const [state, send, service] = useMachine(machine, options)

  useEffect(() => {
    const subscription = service.subscribe((state) => {
      setStoredState(state)
    })

    return subscription.unsubscribe
  }, [service, setStoredState])

  return [state, send]
}
