import Occasion from 'occasion-sdk'

export const occsnClient = () => {
  // const token =
  //   typeof window !== `undefined`
  //     ? ''
  //     : JSON.parse(localStorage.getItem('occasionApp')).api.key
  // FIXME:

  const token =
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhY2Nlc3MiOnsicHJvZHVjdHMiOlsiaW5kZXgiXX0sImV4cGlyZXNfYXQiOjE3NDUxNjgxOTAsInNjb3BlIjoibWVyY2hhbnQ6NTA5NiIsImZyb20iOiJraWVyYW4gc2RrIHNjcmlwdCIsInByaXZhdGUiOnRydWV9.FjI-gCd5WOvJiH7BfLaX6vpx0NEw4oBMnOnztiCQZcw'
  return new Occasion.Client({ token })
}
