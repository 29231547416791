import React from 'react'
import { useLocalStorage } from 'react-use'
import { AppContext } from './contexts/app-context'
import { usePersistedMachine } from './hooks/use-persisted-machine'
import { appMachine } from './machines/app-machine'

export function App({ children }) {
  // See if we have persisted data for the context
  const [app, setApp] = useLocalStorage('occasionApp', {})
  const [state, send] = usePersistedMachine('occasionState', appMachine, {
    actions: {
      setApp: (context, event) => {
        console.log(event.data)
        setApp({ ...event.data, ...app })
      },
      logOut: () => {
        setApp({})
      },
      redirectToLoginPage: () => {
        window.location = '/LoginHere'
      },
    },
  })

  return (
    <AppContext.Provider value={[app, state, send]}>
      {children}
    </AppContext.Provider>
  )
}
